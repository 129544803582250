import React from 'react'
import Blog from '../components/Blog'
import MainLayout from '../components/MainLayout'

const BlogPage = () => {
  return (
    <MainLayout>
      <Blog />
    </MainLayout>
  )
}

export default BlogPage
