import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BackgroundSectionStyle } from '../Section/backgroundSection.css.js'

const StyledPost = styled.div`
  ${ BackgroundSectionStyle };
`

const Post = ({ children }) => {
  return <StyledPost>{children}</StyledPost>
}

Post.propTypes = {
  children: PropTypes.node,
}
export default Post
