import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledPostCopy = styled.div`
  p {
    color: ${ props => props.theme.white };
  }
`

const PostCopy = ({ children }) => {
  return <StyledPostCopy>{children}</StyledPostCopy>
}

PostCopy.propTypes = {
  children: PropTypes.node,
}
export default PostCopy
