import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import BlogHero from './BlogHero'
import { BackgroundSectionStyle } from '../Section/backgroundSection.css.js'
import { Primary, Alternate, Secondary } from '../../components/Section'

import { Post, PostSubtitle, PostTitle, PostCopy } from '../Post'
import { calculateRem } from '../../utils/javascript-styles'
import Img2020FathersDay from '../../images/2020-fathers-day-post.jpg'

const StyledBlogHero = styled(BlogHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? 'center' : 'initial')};
  h1,
  h2,
  h3 {
    color: ${props => props.theme.atoll};
  }
`

const Blog = () => {
  return (
    <section>
      <StyledBlogHero backgroundHeight={550} centered>
        <h1>HOLD ON TO YOUR MONEY</h1>
        <h3>Read our Blog</h3>
      </StyledBlogHero>

      <Secondary>
        <Row>
          <Col xs={12}>
            <Post>
              <img src={Img2020FathersDay} alt="man dialing cell" />
              <PostSubtitle>
                If you have experienced parental alienation or know someone who
                has ... Look in the mirror and read this
              </PostSubtitle>
              <PostTitle>Calling Myself on Father’s Day</PostTitle>
              <PostCopy>
                <p>
                  Good morning ... (Fill in your name) Another beautiful morning
                  it is. Yes, I am staying positive.{' '}
                </p>
                <p>
                  I call myself to wish myself a Happy Father’s Day. My children
                  have been brainwashed – of course they will deny it. This is
                  part of the alienation process. Unfortunately, they have shown
                  no interest to be a part of my life. Therefore, I again call
                  myself.
                </p>
                <p>
                  With alienation and brainwashing you will hear the following:
                  “Hi mom!” at a sporting event. “Happy Father’s Day to my mom
                  who is a single mom” Sadly, in their mind a father does not
                  exist to them. They have been taught to disrespect and hate
                  the thought of their father. Yet checks are cashed every month
                  until college graduation. Of course, in the public eyes, this
                  goes unnoticed and disrespected. “He is required to send a
                  check.” “I am being raised by a single mom.” “She deserves
                  it.”
                </p>
                <p>
                  They do not acknowledge the many years that their father has
                  attempted to be in their lives. Yet the court system loves to
                  use kids as pawns to make money for the billion-dollar divorce
                  industry. I must not forget this. The court system easily
                  destroys families. I will not blame my kids for not calling
                  today.
                </p>
                <p>It’s ok. They are victims.</p>
                <p>
                  Nevertheless, I call myself. I know the love they have missed.
                  They continue to miss the love that I have for them. They
                  missed the love I have always had for my misguided children.{' '}
                </p>
                <p>
                  They have missed the love from grandparents, uncles, aunts,
                  cousins, friends and more. The friends and family of me, their
                  father, are extensions of my life ... So much wisdom to give.
                  So much passion to give So much love to give
                </p>
                <p>
                  I call myself. The love is here. The love has always been
                  here. Just waiting to be received. The courts, false claims
                  and public disregard of a father’s love have become glass
                  fences.
                </p>
                <p>
                  Glass fences will be shattered. It’s only a matter of time.
                  The bat is on their side of the fence.
                </p>
                <p>
                  “Reach out to your father.” Someone needs to tell my children.
                  Of course, they do not listen to me. Maybe today someone will
                  hand them the bat in order to break the glass fence.
                  Nevertheless, until then. I call myself and text myself. Happy
                  Father’s Day. (Fill in your name)
                </p>
              </PostCopy>
            </Post>
          </Col>
        </Row>
      </Secondary>
      <Alternate>
        <Row>
          <Col xs={12}>
            <Post>
              <PostTitle>
                PRENUPTIAL AGREEMENTS ARE NO LONGER STRICTLY FOR THE WEALTHY
              </PostTitle>
              <PostSubtitle>
                Prenuptial Agreements: Can They Protect Future Custody Rights?
              </PostSubtitle>
              <PostCopy>
                <p>
                  Prenuptial agreements are no longer strictly for the wealthy.
                  Many couples are finding prenuptial agreements a helpful
                  option for addressing many monetary issues before marriage.
                  They are beneficial when one or both people have been divorced
                  or have children from a previous marriage, and when one person
                  has a substantially higher income than the other.
                </p>
                <p>
                  In addition to ensuring monetary safety in the case of a
                  divorce, many couples might wonder if a prenuptial agreement
                  can protect an even more important asset-their parental rights
                  for future children. It is not uncommon for courts to favor
                  mothers in child custody cases, and men are often pushed from
                  their children's lives, only getting two weekends per month
                  and a couple of weekends in the summer to spend with their
                  children.
                </p>
                <p>
                  Unfortunately, there are no states in the United States that
                  currently allow child custody issues of future children to be
                  determined in a prenuptial agreement. The best needs of a
                  child cannot be adequately determined before that child is
                  born-circumstances can change so drastically in a
                  relationship, and what may seem like the best scenario for a
                  child in the beginning of a marriage might not be best for
                  them ten years down the line. The courts approach child
                  custody on a case-by-case basis, and take into account the
                  current circumstances of each relationship.
                </p>
                <p>
                  Nevertheless it is unfortunate that a prenuptial agreement
                  cannot protect all of the rights of a father in case of
                  divorce. A child needs both parents actively involved in his
                  or her life in order to flourish and feel loved, and there are
                  many fathers who try to provide this type of stability but are
                  blocked or "Denied" at every turn. Courts need to understand
                  that this hurts the child more than anyone, and fathers need
                  to find the courage to stand up and fight against this
                  injustice.
                </p>
                <p>
                  Please feel free to contact us to learn more about MJMD
                  Mediation & Coaching Services.
                </p>
              </PostCopy>
            </Post>
          </Col>
        </Row>
      </Alternate>
      <Secondary>
        <Row>
          <Col xs={12}>
            <Post>
              <PostTitle>
                Parental Alienation Causes Irreparable Harm to Children
              </PostTitle>
              <PostSubtitle>
                Parental Alienation Causes Irreparable Harm to Children
              </PostSubtitle>
              <PostCopy>
                <p>
                  A broken marriage or partner separation should never mean a
                  breakup with the children. Parental alienation leaves a child
                  confused and adrift in a world that emphasizes family values,
                  but does little to foster parent/child relationships when
                  parents live apart. So often fathers lose custody of their
                  children, and visitation depends on the other parent's
                  kindness or how much money a dad can afford to pay. The price
                  of a child support payment means nothing when compared to the
                  price that the child and estranged father pay by losing out on
                  the relationship that nature intended. Children should never
                  be pawns in a court battle; however, they are often treated as
                  chattel by the legal system. In the groundbreaking book,
                  Forever My Daddy: Denied, Dr. Michael Joyner M.D. champions
                  the cause of the silently suffering fathers who have lost part
                  of themselves through the actions of the court, vindictive
                  ex-spouses, and society's indifferent and often prejudiced
                  attitude concerning the importance of a father's influence.
                  FMD Denied encourages the reader to have the tough discussions
                  that seem taboo in modern society concerning getting ready for
                  marriage, arranging custody through pre- marital agreements,
                  and rebuilding bridges of trust and harmony in broken
                  families. Forever My Daddy: Denied is a great gift to a son,
                  uncle, brother, or any man who loves his children. Forever My
                  Daddy: Denied is required reading for anyone who is
                  considering starting a family as well as those in the painful
                  throes of separation, who fear losing their parental rights.
                </p>
                <p>
                  If you are going through a divorce or considering a divorce
                  you should consider a certified divorce coach as you embark
                  the journey. Avoid parental alienation. Avoid the court room.
                  In order to learn more contact us at MJMD Mediation & Coaching
                  for more information about how you can join this important
                  dialogue and support this cause.
                </p>
              </PostCopy>
            </Post>
          </Col>
        </Row>
      </Secondary>
    </section>
  )
}

export default Blog
